import React, { useState } from 'react';

import Card from '../../components/shared/ui/Card';
import { Icons } from '../../helpers/icons';
import useGenericGetQuery from '../../CustomHooks/useQuery';
import { axiosStripeInstance } from '../../api/apiInstances';
import Loader from '../../components/shared/layout/Loader';

interface QNA {
  Question: string;
  Answer: string;
}

interface FAQSection {
  _id: string;
  QNAs: QNA[];
  category: string;
}

interface ExpandableCardProps {
  qna: QNA;
}

const ExpandableCard = (props: ExpandableCardProps) => {
  const { qna } = props;
  const { Question, Answer } = qna;

  const [isExpanded, setExpanded] = useState<boolean>(false);

  const toggleExpanded = () => {
    setExpanded(!isExpanded);
  };

  return (
    <div className="md:w-full border shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)] rounded-[10px] bg-white p-6 mb-4">
      <div className="flex flex-row justify-between items-center">
        <div className="md:text-lg text-base">{Question}</div>
        <div className="py-2 cursor-pointer" onClick={toggleExpanded}>
          {isExpanded ? <Icons.Dash className="mr-1" /> : <Icons.Plus />}
        </div>
      </div>
      {isExpanded && (
        <div className="mt-4 pt-4 border-t md:text-base text-sm">{Answer}</div>
      )}
    </div>
  );
};

const FAQ = () => {
  const { data: faqData, isFetching } = useGenericGetQuery<FAQSection[]>(
    '/faq',
    axiosStripeInstance,
    undefined,
    undefined,
    { enabled: true }
  );

  if (isFetching) {
    return (
      <div className="items-center justify-center flex flex-1">
        <Loader />
      </div>
    );
  }

  return (
    <>
      <Card
        heading="FAQ"
        subHeading="Frequently Asked Questions"
        className="w-full h-fit mb-5 md:mb-10"
      >
        {faqData?.map((section) => (
          <div key={section._id} className="mb-8">
            <h2 className="text-xl font-bold mb-4 ml-1">{section.category}</h2>
            {section.QNAs.map((qna, index) => (
              <ExpandableCard qna={qna} key={index} />
            ))}
          </div>
        ))}
        <div className="mt-8 p-4 bg-gray-50 rounded-lg text-center text-gray-700">
          For any additional questions or issues, please don't hesitate to reach out to our support team at team@etseo.com or use our real-time chat.
        </div>
      </Card>
    </>
  );
};

export default FAQ;
