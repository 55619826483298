import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const emailRegex =
  /^([\w!#$%&'*+/=?^`{|}~-]+(?:\.[\w!#$%&'*+/=?^`{|}~-]+)*)@((?:[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?\.)+[\dA-Za-z](?:[\dA-Za-z-]*[\dA-Za-z])?)$/;

const firstSpace = /^(?!\s).*/;

export const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords must match')
    .required('Confirm Password is required'),
});

export const signUpResolver = yupResolver(
  Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string()
      .required('Email is required')
      .matches(emailRegex, 'Invalid email'),
    password: Yup.string()
      .required('Password is required')
      .matches(firstSpace, 'Password is required')
      .min(8, 'Password must contain min 8 characters')
      .max(20, 'Password can have max 20 characters')
      .matches(
        /(?=.*[a-z])/,
        'Password must have at least 1 lower-case alphabet'
      )
      .matches(
        /(?=.*[A-Z])/,
        'Password must have at least 1 upper-case alphabet'
      )
      .matches(/(?=.*\d)/, 'Password must have at least 1 numeric character')
      .matches(
        /(?=.*[!@#$%^&*(),.?":{}|<>])/,
        'Password must contain a special character'
      ),
    confirmPassword: Yup.string()
      .required('Password is required')
      .matches(firstSpace, 'Password is required')
      .oneOf([Yup.ref('password'), ''], 'Passwords must match'),
  })
);

export const loginResolver = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(emailRegex, 'Invalid email'),
    password: Yup.string()
      .required('Password is required')
      .matches(firstSpace, 'Password is required'),
  })
);

export const forgetResolver = yupResolver(
  Yup.object().shape({
    email: Yup.string()
      .required('Email is required')
      .matches(emailRegex, 'Invalid email'),
  })
);

export const personalProfileValidationSchema = Yup.object().shape({
  firstName: Yup.string().required('First Name is required'),
  lastName: Yup.string().required('Last Name is required'),
  email: Yup.string().email('Invalid email').required('Email is required'),
  state: Yup.string().email('Invalid state').required('State is required'),
  phoneNumber: Yup.string()
    .email('Invalid phone number')
    .required('Phone number is required'),
});

export const professionalProfileValidationSchema = Yup.object().shape({
  lawJurisdiction: Yup.string().required('Law Firm Jurisdiction is required'),
  licenseNumber: Yup.string().required('Law Firm License Number is required'),
  lawFirm: Yup.string().required('Law Firm is required'),
  practiceAreas: Yup.array()
    .of(
      Yup.object().shape({
        id: Yup.number().required(),
        label: Yup.string().required(),
        checked: Yup.boolean().required(),
      })
    )
    .required('Practice Areas are required'),
});

export const changePasswordResolver = yupResolver(
  Yup.object().shape({
    currentPassword: Yup.string()
      .required('Current Password is required')
      .matches(firstSpace, 'Current Password is required')
      .min(8, 'Password must contain min 8 characters')
      .test('log-current-password', '', function (value) {
        return true;
      }),
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(firstSpace, 'New Password is required')
      .notOneOf(
        [Yup.ref('currentPassword')],
        'New password cannot be the same as the current password'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .matches(firstSpace, 'Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), ''], 'Password doesn’t match'),
  })
);

export const forgotPasswordResolver = yupResolver(
  Yup.object().shape({
    newPassword: Yup.string()
      .required('New Password is required')
      .matches(firstSpace, 'New Password is required')
      .min(8, 'Password must contain min 8 characters')
      .max(20, 'Password can have max 20 characters')
      .matches(
        /(?=.*[a-z])/,
        'Password must have at least 1 lower-case alphabet'
      )
      .matches(
        /(?=.*[A-Z])/,
        'Password must have at least 1 upper-case alphabet'
      )
      .matches(/(?=.*\d)/, 'Password must have at least 1 numeric character')
      .matches(/(?=.*[@$!%*?&])/, 'Password must contain a special character')
      .notOneOf(
        [Yup.ref('currentPassword')],
        'New password cannot be the same as the current password'
      ),
    confirmPassword: Yup.string()
      .required('Confirm Password is required')
      .matches(firstSpace, 'Confirm Password is required')
      .oneOf([Yup.ref('newPassword'), ''], 'Password doesn’t match'),
  })
);

export const websiteRegex =
  /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}(\/[a-zA-Z0-9#?&=._-]*)?\/?$/;
export const etsyStoreRegex =
  /^(?:https?:\/\/)?(?:www\.)?(?:etsy\.com\/(?:ca\/)?shop\/[\w-]+|[\w-]+\.etsy\.com)\/?$/;
  
export const accountDetailsResolver = yupResolver(
  Yup.object().shape({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    website: Yup.string()
      .nullable()
      .notRequired()
      .test('is-valid-website', 'Invalid website', value => {
        return !value || websiteRegex.test(value);
      }),
    etsyStoreUrl: Yup.string()
      .nullable()
      .notRequired()
      .test(
        'is-valid-etsyStoreUrl',
        'Please enter a valid Etsy store URL (e.g., shopname.etsy.com or www.etsy.com/shop/shopname)',
        value => {
          return !value || etsyStoreRegex.test(value);
        }
      ),
  })
);
