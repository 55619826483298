import React, { ReactNode, useState, useRef, useEffect } from 'react';
import { htmlToText } from 'html-to-text';

import { Icons } from '../../../../helpers/icons';
import { copyToClipboard } from '../../../../helpers/utils';
import Alert from '../Alert';
import { StatusResponse } from '../../../../common/types';
import useGenericMutation from '../../../../CustomHooks/useMutation';
import { RootState } from '../../../../redux/store';
import { useSelector } from 'react-redux';

interface CardProps {
  heading?: ReactNode;
  subHeading?: ReactNode;
  selection?: ReactNode;
  children?: any;
  isAlt?: boolean;
  className?: string;
  headingClassName?: string;
  subHeadingClassName?: string;
  altHeadingClassName?: string;
  isHtml?: boolean;
  showNameField?: boolean;
  listingName?: string;
  isBorder?: boolean;
}

const Card: React.FC<CardProps> = React.memo(
  ({
    heading,
    subHeading,
    selection,
    children,
    isAlt,
    className,
    headingClassName,
    subHeadingClassName,
    altHeadingClassName,
    isHtml = false,
    showNameField = false,
    listingName,
    isBorder = true,
  }) => {
    const [alert, setAlert] = useState<{
      isSuccess: boolean;
      message: string;
    } | null>(null);
    const [inputValue, setInputValue] = useState(
      listingName === 'Untitled' ? '' : listingName
    );
    const [originalValue, setOriginalValue] = useState(
      listingName !== 'Untitled' ? listingName : ''
    );
    const [isEditing, setIsEditing] = useState(false);
    const inputRef = useRef<HTMLInputElement>(null);

    const listingIdOfNewListing = useSelector(
      (state: RootState) => state.listing.listingId
    );

    const { mutateAsync: saveEditedListingName } =
      useGenericMutation<StatusResponse>(
        `/listing/${listingIdOfNewListing}`,
        'PUT'
      );

    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isEditing]);

    useEffect(() => {
      setInputValue(listingName === 'Untitled' ? '' : listingName);
    }, [listingName]);

    const handleCopy = () => {
      const content = isHtml
        ? htmlToText(children.props.dangerouslySetInnerHTML.__html, {
            preserveNewlines: false,
            wordwrap: false,
            tags: {
              p: { options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
              h1: { options: { leadingLineBreaks: 2, trailingLineBreaks: 1 } },
              h2: { options: { leadingLineBreaks: 2, trailingLineBreaks: 1 } },
              h3: { options: { leadingLineBreaks: 2, trailingLineBreaks: 1 } },
              h4: { options: { leadingLineBreaks: 2, trailingLineBreaks: 1 } },
              h5: { options: { leadingLineBreaks: 2, trailingLineBreaks: 1 } },
              ul: { options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
              ol: { options: { leadingLineBreaks: 1, trailingLineBreaks: 1 } },
            },
          })
        : children;
      copyToClipboard(content, setAlert);
    };

    const closeAlert = () => {
      setAlert(null);
    };

    const handleSave = async () => {
      if (inputValue?.trim() !== '' && inputValue !== originalValue) {
        setOriginalValue(inputValue);
        try {
          await saveEditedListingName({
            name: inputValue,
          });
        } catch (error) {
          console.log('error', error);
        }
      }
      setIsEditing(false);
    };

    const handleEdit = () => {
      setIsEditing(true);
    };

    return (
      <div
        className={`border rounded-[10px] bg-white ${className} ${
          isAlt
            ? 'w-full mx-auto p-4 md:p-5'
            : 'p-[20px] md:p-[40px] shadow-[0px_0px_12px_0px_rgba(74,131,184,0.12)]'
        }`}
      >
        {alert && (
          <Alert
            isSuccess={alert.isSuccess}
            message={alert.message}
            onClose={closeAlert}
          />
        )}
        {isAlt ? (
          <>
            <div className="mb-3 flex justify-between items-center">
              <h2
                className={`font-bold text-base md:text-2xl md:${altHeadingClassName}`}
              >
                {heading}
              </h2>
              <button
                className="text-etsyPrimary text-sm font-medium flex items-center"
                onClick={handleCopy}
              >
                <Icons.Copy className="mr-1" />
                Copy
              </button>
            </div>
            <p className="text-gray-700 text-xs md:text-sm leading-6">
              {children}
            </p>
          </>
        ) : (
          <>
            {(heading || subHeading) && (
              <div
                className={`flex flex-col w-full md:flex-row justify-between ${isBorder ? 'border-b pb-8 md:mb-10' : ''}  mb-6 md:items-center`}
              >
                <div className="flex flex-col flex-1">
                  {showNameField && (
                    <div className="flex items-center">
                      {isEditing ? (
                        <input
                          ref={inputRef}
                          type="text"
                          placeholder={
                            listingName === 'Untitled'
                              ? 'Name your listing'
                              : 'Listing Name'
                          }
                          value={inputValue || ''}
                          className="outline-none border-none bg-transparent font-extrabold text-[44px] mb-2 w-[353px] text-black placeholder-gray-400 placeholder-opacity-30"
                          style={{ textDecoration: 'none' }}
                          onChange={e => setInputValue(e.target.value)}
                          onBlur={handleSave}
                        />
                      ) : (
                        <div className="flex items-center">
                          <div className="flex-grow overflow-hidden min-w-[320px] cursor-default">
                            <div className="font-extrabold text-[44px] mb-2 text-black truncate">
                              {inputValue || (
                                <span className="opacity-30">
                                  {listingName === 'Untitled' || undefined
                                    ? 'Name your listing'
                                    : listingName || 'Name your listing'}
                                </span>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                      <button
                        onClick={isEditing ? handleSave : handleEdit}
                        className="ml-2 flex-shrink-0 text-etsyPrimary hover:text-black transition-colors duration-200"
                      >
                        <Icons.Pencil className="w-11 h-11" />
                      </button>
                    </div>
                  )}
                  {heading && !showNameField && (
                    <div
                      className={`text-xl md:text-[32px] font-extrabold pb-4 ${headingClassName}`}
                    >
                      {heading}
                    </div>
                  )}
                  {subHeading && (
                    <div
                      className={`text-sm md:text-base font-semibold tracking-[1px] text-gray-400 ${subHeadingClassName}`}
                    >
                      {subHeading}
                    </div>
                  )}
                </div>
                <div>{selection}</div>
              </div>
            )}
            {children}
          </>
        )}
      </div>
    );
  }
);

export default Card;
