/* eslint-disable eqeqeq */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useUser } from '@clerk/clerk-react';

import Card from '../../components/shared/ui/Card';
import BillingPlanModal from '../../components/AccountDetails/BillingPlansModal';
import ChangePasswordModal from '../../components/AccountDetails/ChangePasswordModal';
import useGenericGetQuery from '../../CustomHooks/useQuery';
import {
  axiosStripeInstance,
  useAxiosStripeInterceptors,
} from '../../api/apiInstances';
import { MyStripeSubscription } from '../../common/types/stripeSessions';
import Loader from '../../components/shared/layout/Loader';
import UserProfileForm from '../../components/shared/ui/UserProfileForm';

const AccountDetails = () => {
  const { user } = useUser();
  const userId = user?.id;
  useAxiosStripeInterceptors();

  const [passwordModalOpened, setPasswordModalOpened] = useState(false);
  const [isQueryEnabled, setIsQueryEnabled] = useState(true);
  const [isBottom, setIsBottom] = useState(false);

  const { data: availablePlans, isFetching } =
    useGenericGetQuery<MyStripeSubscription>(
      '/subscription/all-subscriptions',
      axiosStripeInstance,
      { userId },
      undefined,
      { enabled: isQueryEnabled }
    );

  const currentPlan = useMemo(() => {
    return (
      availablePlans?.basicPlans.find(
        plan =>
          plan.currentPlan === true ||
          plan.status == 'active' ||
          plan.status == 'expired'
      ) || null
    );
  }, [availablePlans]);

  useEffect(() => {
    setIsQueryEnabled(false);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;

      if (windowHeight + scrollTop >= documentHeight - 400) {
        setIsBottom(true);
      } else {
        setIsBottom(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const renderBillingHeading = useCallback(() => {
    return (
      <div className="flex flex-col md:flex-row md:items-center justify-center bg-[#F3EDE9] rounded-lg py-6 md:px-2 px-3">
        {currentPlan ? (
          <div className="md:flex md:items-center md:text-lg text-sm gap-x-6">
            <div>
              <span>Your Current Bundle: </span>
              <span className="md:text-base font-normal">
                {currentPlan?.title}
              </span>
            </div>
            <div>
              <span>Total Listings: </span>
              <span className="md:text-base font-normal">
                {currentPlan?.totalListings}
              </span>
            </div>
            <div>
              <span>Remaining Listings: </span>
              <span className="md:text-base font-normal">
                {currentPlan?.remainingListings}
              </span>
            </div>
          </div>
        ) : (
          <div className="md:text-lg text-sm self-center">
            You have not subscribed to any plan, please select a plan...
          </div>
        )}
      </div>
    );
  }, [currentPlan]);

  return (
    <div id="profile" className="min-w-full md:pb-[40px] pb-8">
      <Card
        heading="Account Detail"
        subHeading="Customize your account settings below"
      >
        <UserProfileForm />
        <div className="md:flex justify-between md:items-center pb-8 mb:pb-11 mb-8 border-b">
          <h2 className="font-bold text-lg md:font-extrabold md:text-2xl mb-4 md:mb-0">
            Password
          </h2>
          <button
            type="button"
            className="border-[1px] px-6 py-3 rounded-lg border-etsyPrimary font-bold text-lg text-etsyPrimary w-full md:w-auto"
            onClick={() => setPasswordModalOpened(true)}
          >
            Change Password
          </button>
        </div>
        <div>
          <h2
            id="billing-management"
            className="font-bold text-xl md:font-extrabold md:text-2xl mb-6"
          >
            Billing Management
          </h2>
          {isFetching ? (
            <Loader />
          ) : (
            <Card
              className={'!p-6 !pb-8'}
              heading={renderBillingHeading()}
              headingClassName={'md:text-xl !text-base !font-semibold'}
              isBorder={false}
            >
              <div
                className={`flex flex-row justify-between w-100 cursor-pointer ${isBottom ? 'bbb' : ''}`}
              />
              <BillingPlanModal availablePlans={availablePlans} />
            </Card>
          )}
        </div>
        <ChangePasswordModal
          isOpen={passwordModalOpened}
          closeModal={() => setPasswordModalOpened(false)}
          onSave={() => null}
        />
      </Card>
    </div>
  );
};

export default AccountDetails;
